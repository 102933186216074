import React, { useState } from "react";
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import WhiteTextField from './WhiteTextField';

function NewsletterSignup({ width }) {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [signedUp, setSignedUp] = useState(false);
  const [message, setMessage] = useState("");

  function validateEmail(email) {
    const regex = /^\S+@\S+\.\S+$/;
    return regex.test(email);
  }

  function clearState() {
    setLoading(false);
    setSignedUp(false);
    setMessage("");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (signedUp) {
      return;
    }
    clearState();
    setLoading(true);

    if (!email) {
      setMessage("");
      return;
    }
    if (!validateEmail(email)) {
      setMessage("Please check the entered email.");
      return;
    }

    const formData = new URLSearchParams();
    formData.append("email", email);
    try {
      const response = await fetch("https://script.google.com/macros/s/AKfycbyAlQtIuf1SPWmV6iLkH_RYeICMoQpXMiOoiWZCgf4ih-ObX_B_pmEgK__2vE86YZiY/exec", {
        method: "POST",
        body: formData,
      });
      const result = await response.json();
      if (result.status === "success") {
        setMessage("You're all set!");
        setSignedUp(true);
        setLoading(false);
      } else {
        setMessage("Error signing up, please try again.");
      }
    } catch (error) {
      console.error("Error submitting email");
    }
  };

  return (
    <Box sx={{ "& > :not(style)": { m: 1, mt: 5, width: width } }}>
      <WhiteTextField
        type="email"
        sx={{ color: "white", input: { color: "white" } }}
        label="Email"
        aria-label="Enter your email address"
        placeholder="How can we reach you?"
        size="large"
        slotProps={{
          input: {
            endAdornment: (
              <InputAdornment
                sx={{ mr: 1, visibility: validateEmail(email) ? "block" : "hidden" }}
                position="end"
              >
                <IconButton
                  sx={{ "&:hover": { backgroundColor: "#0290C3", color: "white" } }}
                  edge="end"
                  onClick={handleSubmit}
                  disabled={!validateEmail(email) || signedUp || loading}
                  aria-hidden={!validateEmail(email)}
                  aria-label={validateEmail(email) ? "Submit email" : ""}
                >
                  {
                    !signedUp 
                    ? (
                      !loading 
                      ? <NavigateNextIcon sx={{ color: "white", fontSize: "25px" }} /> 
                      : <CircularProgress size="25px" sx={{ color: "white" }} />
                    ) 
                    : <CheckIcon sx={{ color: "white", fontSize: "25px" }} />
                  }
                </IconButton>
              </InputAdornment>
            )
          },
        }}
        FormHelperTextProps={{ style: { color: "white", fontSize: "15px" }}}
        helperText={message || " "}
        value={email}
        onChange={(e) => {
          clearState();
          setEmail(e.target.value);
        }}
      />
    </Box>
  );
};

export default NewsletterSignup;
