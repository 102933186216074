import logo from './logo_w.svg';
import Footer from './components/Footer';
import NewsletterSignup from './components/NewsletterSignup';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSpring, animated } from '@react-spring/web';
import './App.css';

function App() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [props, api] = useSpring(
    () => ({
      from: { opacity: 0 },
      to: { opacity: 1 },
      config: { duration: 500 },
    }),
    []
  );

  return isSmallScreen ? (
    <animated.div style={props}>
      <div className="App">
        <img
          src={logo}
          style={{
            height: "15vmin",
            pointerEvents: "none",
            marginBottom: "20vmin",
          }}
          alt="logo"
        />
        <Typography sx={{ color: "white", fontFamily: "EB Garamond, serif", mb: "15vmin", maxWidth: "90vw" }} variant="h4" fontWeight='100'>
          The next generation of mental healthcare is about <Typography display="inline" sx={{ fontFamily: "Gwendolyn, cursive", fontWeight: 700, lineHeight: "1.2rem" }} variant="h2">you&nbsp;</Typography>
        </Typography>
        <NewsletterSignup width="70vw" />
      </div>
    </animated.div>
  ) : (
    <animated.div style={props}>
      <div className="App">
        <img
          src={logo}
          style={{
            height: "10vmin",
            pointerEvents: "none",
            marginBottom: "3vmin",
          }}
          alt="logo"
        />
        <Typography sx={{ color: "white", fontFamily: "EB Garamond, serif" }} variant="h3" fontWeight='100' gutterBottom>
          The next generation of mental healthcare is about <Typography display="inline" sx={{ fontFamily: "Gwendolyn, cursive", fontWeight: 700 }} variant="h1">you&nbsp;</Typography>
        </Typography>
        <NewsletterSignup width="50ch" />
        <Footer />
      </div>
    </animated.div>
  );
}

export default App;
