import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const rot13 = (str) => {
  return str.replace(/[a-zA-Z]/g, function (char) {
    return String.fromCharCode((char <= "Z" ? 90 : 122) >= (char = char.charCodeAt(0) + 13) ? char : char - 26);
  });
};

function obfuscateEmail(user) {
  const email = `${user}@withasra.com`;
  const encodedEmail = rot13(email);
  return encodedEmail;
}

const FooterTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'transparent',
    maxWidth: "80vw",
    borderRadius: 0,
    borderBottom: '1px solid #dadde9',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
  },
}));

function Footer() {
  const obfCareersAddress = obfuscateEmail("careers");
  const obfInvestorsAddress = obfuscateEmail("investors");

  const careersMessage = (
    <Typography variant="body1" sx={{ m: 1 }}>
      Send us your resume here. We can't wait to meet you!
    </Typography>
  );
  const investorsMessage = (
    <Typography variant="body1" sx={{ m: 1 }}>
      Please reach out to us via email.
    </Typography>
  );

  return (
    <div 
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        minWidth: "100vw",
        padding: "20px 0",
      }}
    >
      <Grid container spacing={2}>
        <Grid size={5}></Grid>
        <Grid size={1}>
          <a href={`mailto:${rot13(obfCareersAddress)}`}>
            <Typography
              variant="button"
              sx={{
                fontWeight: "400",
                paddingTop: "5px",
                cursor: "pointer",
                '&:hover': { fontWeight: "900" }
              }}
            >
              Careers
            </Typography>
          </a>
        </Grid>
        <Grid size={1}>
          <a href={`mailto:${rot13(obfInvestorsAddress)}`}>
            <Typography
              variant="button"
              sx={{
                fontWeight: "400",
                paddingTop: "5px",
                cursor: "pointer",
                '&:hover': { fontWeight: "900" }
              }}
            >
              Investors
            </Typography>
          </a>
        </Grid>
        <Grid size={5}></Grid>
      </Grid>
    </div>
  );
}

export default Footer;
